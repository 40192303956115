<!--
 * @Author: your name
 * @Date: 2021-12-07 15:40:36
 * @LastEditTime: 2022-01-26 09:56:34
 * @LastEditors: Please set LastEditors
 * @Description: 新闻动态
 * @FilePath: \new-website-1123\src\views\news\home\index.vue
-->
<template>
  <div class="special-home pt80 outer_clear">
    <div class="swiper-connect">
      <swiper
        class="swiper special-home-swiper"
        ref="swiper"
        :options="swiperOptions"
        v-if="navList.length > 0"
      >
        <swiper-slide v-for="item in navList" :key="item.id" :index="item.id">
          <div class="special-home-slide">
            <!-- <div class="special-home-slide-connect fl">
              <h2>{{ item.title }}</h2>
              <h4>{{ item.label }}</h4>
              <div class="special-home-more" @click.stop="goMore(item.url)">
                <span>了解更多</span
                ><img
                  class="ml10"
                  width="40"
                  src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/common/jr.png"
                  alt="上太科技"
                />
              </div>
            </div> -->
            <img :src="item.cover" class="right-banner" @click="goProMore(item)" />
          </div>
        </swiper-slide>
      </swiper>

      <div class="swiper-pagination" id="swiper-pagination1">
        <span class="swiper-pagination-bullet"></span>
      </div>
    </div>

    <div class="special-nav outer_clear">
      <div
        v-for="(item, index) in Specialnav"
        :key="index"
        class="special-nav-demo"
        @click="checkspec(index, item.title)"
        :class="{ active: changeNumber === index }"
      >
        {{ item.title }}
      </div>
    </div>

    <ul class="special-product outer_clear">
      <div
        v-for="(item, index) in Specialnav"
        :key="index"
        class="special-product-mbtitle"
      >
        <span v-if="changeNumber === index"> {{ item.title }}</span>
      </div>

      <li
        v-for="(item, index) in SpecialListtwo"
        :key="index"
        @click.stop="goMore(item.url)"
      >
        <div class="special-product-connect animation-3">
          <h2 v-if="item.title">{{ item.title }}</h2>
          <h4>{{ item.title_label }}</h4>
          <img :src="item.img" class="special-product-img" />
          <div class="special-product-more animation-3"></div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {getBannerList,getMore}from '@/utils/getBanner.js'
export default {
  name: "special-home",
  components: {},
  data() {
    return {
      changeNumber: 0,
      bannerback: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/bannerback.png",
      navList: [
        // {
        //   id: 1,
        //   title: "智能虫情测报仪",
        //   label:
        //     "ST-CQ101虫情测报仪独有转筒式通道切换功能，高精度完成切换模式；轻松完成自主杀虫、残骸称重、自动倾倒三部；虫体完整率达到新高度；搭配精准AI识别，配合上太云物联平台，科学解析虫害详情",
        //   cover: "https://via.placeholder.com/1920x500?text=banner1",
        //   url: "/special/CQ101",
        // },
        // {
        //   id: 2,
        //   title: "智能高空虫情测报仪",
        //   label:
        //     "针对草地贪夜蛾等迁飞性害虫防控，量身定做的专业级设备。采用高空投射灯，光控、时控、雨控、远程智能控制；自主杀虫，残骸称重，自动清倒一体化；玻璃球罩不易积虫，虫体不堆叠，维护更方便；球罩耐高温，雨天不炸裂，更加安全；多种端口控制，足不出户就能了解一切。",
        //   cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/banner-img2.png",
        //   url: "/special/GK101",
        // },
        // {
        //   id: 3,
        //   title: "光伏自动气象测报站",
        //   label:
        //     "光伏自动气象测报站可监测空气温湿度、风速、风向、气压、雨量、光照强度、总辐射量等常规气象要素，可在无人值守的恶劣环境下全天候全自动运行，将所测数据通过4G/5G网络传输到物联网平台存储和分析。系统利用太阳能供电，无需另外接电。",
        //   cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/banner-img3.png",
        //   url: "/special/x101",
        // },
        // {
        //   id: 4,
        //   title: "智能水肥机",
        //   label:
        //     "本机借助压力系统，将可溶性固体或液体肥料，按土壤减分含量和作物各类的需肥规律和特点，配总成的肥液与灌溉水汪起，通过可控管道系统供水，供肥，使水肥相融后，通过管道和滴头均匀的定时定量浸润作物根系。根据不同作物的需肥特点，土壤环境...",
        //   cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/banner-img4.png",
        //   url: "/special/SF101",
        // },
        // {
        //   id: 5,
        //   title: "太阳能墒情仪",
        //   label:
        //     "采用介电常数原理监测土壤水分含量以及温度状态进行动态观测，并可利用4G/5G技术，将所测数据上报到远程联物网平台。整体可以检测3层~5层或定制层数土壤温湿度状态；可以快速、全面的了解土壤墒情信息，科学地制定灌溉计划与抗旱救灾方案。",
        //   cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/banner-img5.png",
        //   url: "/special/SQ101",
        // },
      ],
      swiperOptions: {
        pagination: {
          el: "#swiper-pagination1", //分页器的id
          type: "bullets",//分页器类型
          clickable: true // 允许点击跳转,
        },
        loop: true,//循环
        speed: 800,//切换速度
        mousewheel: false,//鼠标滚轮
        slidesPerGroup: 1, //每次滑动几个
        autoplay: { //自动播放
          delay: 3000,//3秒切换一次
          stopOnLastSlide: false, //最后一个是否停止
          disableOnInteraction: false, //手动滑动之后不打断播放
        },
      },
      Specialnav: [
        {
          title: "全部",

        },
        {
          title: "虫情测报仪",
        },
        {
          title: "气象测报站",
        },
        {
          title: "水肥机",
        },
        {
          title: "墒情仪",
        },
        {
          title: "孢子仪",
        },
        {
          title: "捕虫器",
        },
        {
          title: "无人车",
        },
        {
          title: "无人机",
        },
        {
          title: "有机肥",
        },
      ],
      SpecialList: [
        {
          title: "ST-CQ101",
          title_label: "智能虫情测报仪",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product1.png",
          url: "/special/CQ101",
          sign: "虫情测报仪",
        },
        {
          title: "ST-GK101",
          title_label: "智能高空虫情测报仪",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product2.png",
          url: "/special/GK101",
          sign: "虫情测报仪",
        },
        {
          title: "ST-QX101",
          title_label: "光伏自动气象测报站",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product3.png",
          url: "/special/x101",
          sign: "气象测报站",
        },
        {
          title: "ST-SF101",
          title_label: "智能水肥机",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product4.png",
          url: "/special/SF101",
          sign: "水肥机",
        },
        {
          title: "ST-SQ101",
          title_label: "太阳能墒情仪",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product5.png",
          url: "/special/SQ101",
          sign: "墒情仪",
        },
        {
          title: "ST-BZ101",
          title_label: "智能孢子捕获仪",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product6.png",
          url: "/special/BZ101",
          sign: "孢子仪",
        },
        {
          title: "ST-FX101",
          title_label: "太阳能捕虫器ST",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product7.png",
          url: "/special/fx101",
          sign: "捕虫器",
        },
        {
          title: "ST-FX301",
          title_label: "太阳能捕虫器ST",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product8.png",
          url: "/special/fx301",
          sign: "捕虫器",
        },
        {
          title: "ST-LD/FX",
          title_label: "太阳能路灯捕虫器",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product9.png",
          url: "/special/stld",
          sign: "捕虫器",
        },

        {
          title: "ST-XJ101",
          title_label: "智能巡检无人车",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product10.png",
          url: "/special/XJ101",
          sign: "无人车",
        },
        {
          title: "ST-TK101",
          title_label: "全地形大负载多功能无人车",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product11.png",
          url: "/special/TK101",
          sign: "无人车",
        },
        {
          title: "",
          title_label: "智能巡检无人机",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product12.png",
          url: "/special/UAV",
          sign: "无人机",
        },
        {
          title: "",
          title_label: "多功能无人机",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product13.png",
          url: "/special/MultifunctionalUAV",
          sign: "无人机",
        },
        {
          title: "ST-YG101",
          title_label: "生物有机肥",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product14.png",
          url: "/special/yg101",
          sign: "有机肥",
        },
      ],
      SpecialListtwo: [
        {
          title: "ST-CQ101",
          title_label: "智能虫情测报仪",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product1.png",
          url: "/special/CQ101",
          sign: "虫情测报仪",
        },
        {
          title: "ST-GK101",
          title_label: "智能高空虫情测报仪",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product2.png",
          url: "/special/GK101",
          sign: "虫情测报仪",
        },
        {
          title: "ST-QX101",
          title_label: "光伏自动气象测报站",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product3.png",
          url: "/special/x101",
          sign: "气象测报站",
        },
        {
          title: "ST-SF101",
          title_label: "智能水肥机",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product4.png",
          url: "/special/SF101",
          sign: "水肥机",
        },
        {
          title: "ST-SQ101",
          title_label: "太阳能墒情仪",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product5.png",
          url: "/special/SQ101",
          sign: "墒情仪",
        },
        {
          title: "ST-BZ101",
          title_label: "智能孢子捕获仪",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product6.png",
          url: "/special/BZ101",
          sign: "孢子仪",
        },
        {
          title: "ST-FX101",
          title_label: "太阳能捕虫器ST",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product7.png",
          url: "/special/fx101",
          sign: "捕虫器",
        },
        {
          title: "ST-FX301",
          title_label: "太阳能捕虫器ST",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product8.png",
          url: "/special/fx301",
          sign: "捕虫器",
        },
        {
          title: "ST-LD/FX",
          title_label: "太阳能路灯捕虫器",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product9.png",
          url: "/special/stld",
          sign: "捕虫器",
        },

        {
          title: "ST-XJ101",
          title_label: "智能巡检无人车",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product10.png",
          url: "/special/XJ101",
          sign: "无人车",
        },
        {
          title: "ST-TK101",
          title_label: "全地形大负载多功能无人车",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product11.png",
          url: "/special/TK101",
          sign: "无人车",
        },
        {
          title: "",
          title_label: "智能巡检无人机",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product12.png",
          url: "/special/UAV",
          sign: "无人机",
        },
        {
          title: "",
          title_label: "多功能无人机",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product13.png",
          url: "/special/MultifunctionalUAV",
          sign: "无人机",
        },
        {
          title: "ST-YG101",
          title_label: "生物有机肥",
          img: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/special/product/product14.png",
          url: "/special/yg101",
          sign: "有机肥",
        },
      ],
    };
  },
  created() {
    this.getBanner()
  },
  computed: {},
  methods: {
    getBanner(){
      getBannerList('p5pro1Ban05').then(res=>{
        this.navList = res.reverse()
      })
    },
    goMore(data) {
      this.$router.push(data);
    },
    goProMore(data){
      const url = getMore(data.label)
      this.$router.push(url);
    },
    checkspec(index, title) {
      var self = this;
      self.changeNumber = index;
      var stitle = title;
      if (index == 0) {
        self.SpecialListtwo = self.SpecialList;
      } else {
        self.SpecialListtwo = self.SpecialList.filter(
          (item) => item.sign === stitle
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.special-home {
  .swiper-connect {
    position: relative;
    .swiper-pagination {
      bottom: 50px;
      left: 18%;
      text-align: left;
      ::v-deep {
        .swiper-pagination-bullet {
          width: 60px;
          height: 2px;
          border-radius: 0;
          margin: 0 3px;
          background-color: rgba($color: #fff, $alpha: 0.8);
          &.swiper-pagination-bullet-active {
            background-color: $color-active;
          }
        }
      }
    }
    .special-home-swiper {
      height: 504px;
      margin-bottom: 100px;
      .special-home-slide {
        // width: $public-width;
        width:100vw;
        height: 500px;
        margin: 0 auto;
        cursor: pointer;
        .right-banner {
          width: 100vw;
          height: 100%;
        }
        .special-home-slide-connect {
          color: $color-text;
          width: 630px;
          padding-top: 130px;
          h2 {
            font-size: 30px;
            margin-bottom: 40px;
            font-weight: normal;
          }
          h4 {
            line-height: 30px;
            font-size: 14px;
            font-weight: normal;
            margin-bottom: 80px;
          }
          .special-home-more {
            font-size: 18px;
            cursor: pointer;
            color: $color-active;
            img {
              width: 40px;
              -o-object-fit: contain;
              object-fit: contain;
              transition: all 0.3s;
            }
            &:hover img {
              transform: translateX(5px);
            }
          }
        }


      }
    }
  }
  .special-nav {
    width: $public-width;
    margin: 0 auto;
    height: 35px;
    line-height: 34px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    .special-nav-demo {
      min-width: 80px;
      text-align: center;
      float: left;
      margin-left: 30px;
      margin-right: 30px;
      position: relative;
      color: #b3b3b3;
      cursor: pointer;
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s;
        width: 0%;
        height: 1px;
        bottom: -1px;
        background: $color-active;
      }
      &:hover {
        color: $color-active;
        &::after {
          width: 100%;
        }
      }
      &.active {
        color: $color-active;
        &::after {
          width: 100%;
        }
      }
    }
  }

  .special-product {
    width: $public-width;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 180px;
    .special-product-mbtitle {
      display: none;
    }
    li {
      width: 33.3333%;
      float: left;
      padding-bottom: 30px;
      padding-right: 30px;
      padding-top: 30px;
      .special-product-connect {
        background: linear-gradient(135deg, #1d1d1d, #0c0c0c);
        height: 274px;
        padding-top: 46px;
        padding-left: 46px;
        position: relative;
        color: $color-text;
        cursor: pointer;
        top: 0;
        &:hover {
          background: #16241e;
          top: -10px;
        }
        h2,
        h4 {
          font-weight: normal;
          font-size: 20px;
          margin-bottom: 20px;
        }
        .special-product-img {
          position: absolute;
          right: 0;
          bottom: 0;
          max-width: 100%;
        }
        .special-product-more {
          background: #1dbf73;
          color: $color-text;
          width: 80px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          border-radius: 666px;
          font-size: 12px;
          margin-top: 90px;
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
          &::after {
            content: "了解更多";
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-width) {
  .special-home {
    .swiper-connect {
      display: none;
    }
    .special-nav {
      width: 2.09rem;
      float: left;
      padding-top: 0.27rem;
      border-bottom: none;
      height: 100vh;
      background: #1a1a1a;
      .special-nav-demo {
        margin: 0;
        width: 100%;
        height: 0.76rem;
        line-height: 0.76rem;
        &.active {
          background: rgba(29, 191, 115, 0.1);
          font-weight: bold;
          &::after {
            display: none;
          }
        }
      }
    }
    .special-product {
      width: 100%;
      width: calc(100% - 2.09rem);
      float: right;
      height: 100vh;
      overflow: auto;
      padding-bottom: 0;
      padding-top: 0.55rem;
      .special-product-mbtitle {
        display: block;
        text-align: center;

        transform: translateY(-0.1rem);
        span {
          color: #fff;
          font-size: 0.24rem;
          position: relative;
          font-weight: bold;
          display: inline-block;
          height: 0.3rem;
          &::after {
            content: "-";
            position: absolute;
            left: -0.27rem;
          }
          &::before {
            content: "-";
            position: absolute;
            right: -0.27rem;
          }
        }
      }
      li {
        width: 100%;
        padding-right: 0.27rem;
        padding-bottom: 0.18rem;
        padding-left: 0.18rem;
        padding-top: 0.18rem;
        .special-product-connect {
          height: 2.49rem;
          padding-top: 0.42rem;
          padding-left: 0.42rem;
          h2 {
            font-size: 0.22rem;
            margin-bottom: 0.18rem;
          }
          h4 {
            font-size: 0.22rem;
            margin-bottom: 0.18rem;
          }
          .special-product-img {
            height: 100%;
          }
          .special-product-more {
            margin-top: 0.6rem;
            font-size: 0.25rem;
            width: 0.82rem;
            height: 0.36rem;
            line-height: 0.36rem;
            &::after {
              content: "查看";
            }
          }
        }
      }
    }
  }
}
</style>
